import React, { useEffect, useMemo, useState } from 'react';
import { TextField, Skeleton, Typography, Stack, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import parse from 'html-react-parser';
import { folioBlue } from '@/utils/constants';
import { determineContext } from '@/hooks/determineContext';
import { useUpdateTaskMutation } from '@/features/engagements/engagementsApi';

export const TaskSubjectUpdateField = ({ title, task, isSubject = false }) => {
	const { deal, objectType, loading, dealRow, client, contact } = determineContext();

	const objectId = (objectType === 'client' ? client : objectType === 'contact' ? contact : deal)
		?.hs_object_id;

	const loadingDeal = loading?.deal;

	const [newText, setNewText] = useState(title);
	const [isEditing, setIsEditing] = useState(false);

	const [updateTask, { isLoading }] = useUpdateTaskMutation();

	useEffect(() => {
		setNewText(isSubject ? task?.subject : parse(task?.body ?? ''));
	}, [task?.body, task?.subject]);

	const handleUpdate = async () => {
		const params = {
			[isSubject ? 'hs_task_subject' : 'hs_task_body']: newText,
		};
		await updateTask({
			dealId: objectId,
			taskId: task.id,
			objectType,
			properties: params,
		}).unwrap();
	};

	const handleSaveClick = () => {
		handleUpdate();
		setIsEditing(false);
	};

	const handleCancelClick = () => {
		setNewText(title);
		setIsEditing(false);
	};

	return (
		<Stack direction='row' alignItems='center' spacing={1} width='100%'>
			{loadingDeal || isLoading ? (
				<Skeleton animation='wave' width='100%' height='2em' />
			) : isEditing ? (
				<TextField
					variant='standard'
					className='minimal-input'
					value={newText}
					disabled={loadingDeal}
					onChange={(e) => setNewText(e.target.value)}
					fullWidth
					maxRows={4}
					multiline
				/>
			) : (
				<Typography
					variant='task_body'
					sx={{
						textDecoration: task.completed ? 'line-through' : 'none',
					}}
				>
					{title}
				</Typography>
			)}
			<>
				<IconButton
					sx={{ color: isEditing ? null : folioBlue, marginLeft: 5 }}
					aria-label='edit'
					disabled={loadingDeal}
					edge='start'
					onClick={isEditing ? handleCancelClick : () => setIsEditing(true)}
				>
					{isEditing ? <CloseIcon /> : <EditIcon />}
				</IconButton>
				{isEditing && (
					<IconButton
						sx={{ color: folioBlue }}
						onClick={handleSaveClick}
						disabled={loadingDeal}
					>
						<DoneIcon />
					</IconButton>
				)}
			</>
		</Stack>
	);
};
