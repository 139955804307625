import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import { DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid';

export const EndorsementsTable = ({
	rows,
	updateRows,
	setRowSelectionModel,
	rowSelectionModel,
	disabled,
}) => {
	useEffect(() => {
		console.log('ROWS for endorsement table', rows);
	}, [rows]);

	const columns = [
		{
			...GRID_CHECKBOX_SELECTION_COL_DEF,
			renderHeader: () => <></>,
			renderCell: (params) =>
				params.row.insurer === '' ? (
					<></>
				) : (
					GRID_CHECKBOX_SELECTION_COL_DEF.renderCell(params)
				),
		},
		{
			field: 'insurer',
			headerName: 'Insurer',
			width: 300,
			editable: false,
			sortable: false,
		},
		{
			field: 'endorsements',
			headerName: 'Endorsements',
			width: 600,
			editable: !disabled,
			sortable: false,
			renderCell: (params) =>
				params.row.insurer !== '' && (params.row.endorsements ?? '').length === 0 ? (
					<Typography sx={{ color: 'rgba(0,0,0,0.5)' }}>Enter endorsements</Typography>
				) : (
					params.row.endorsements
				),
		},
	];

	return (
		<DataGrid
			sx={{
				'& .MuiDataGrid-editInputCell': {
					backgroundColor: 'transparent',
				},
				'& .MuiDataGrid-columnHeadersInner': {
					width: '100%'
				},
				'& .MuiDataGrid-columnHeadersInner > div[role="row"]': {
					width: '100%',
				},
				'& .MuiDataGrid-columnHeader[aria-colindex="3"]': {
					width: '100% !important',
					maxWidth: '100% !important'
				},
				'& .MuiDataGrid-cell[aria-colindex="3"]': {
					overflow: 'auto !important',
					whiteSpace: 'normal !important',
					width: '100% !important',
					maxWidth: '100% !important',
				},
				'& .MuiDataGrid-cell:empty:last-child': {
					display: 'none',
				},
			}}
			width='100%'
			getRowHeight={() => 'auto'}
			processRowUpdate={updateRows}
			rows={rows}
			columns={columns}
			isCellEditable={(params) => {
				if (disabled || params.field === 'insurer') {
					return false;
				}
				return (params.row.insurer ?? '').length > 0;
			}}
			isRowSelectable={() => false}
			getRowId={(row) => `${row.insurer}`}
			checkboxSelection
			onRowSelectionModelChange={setRowSelectionModel}
			rowSelectionModel={rowSelectionModel}
			disableRowSelectionOnClick
			disableSelectionOnClick
			disableColumnFilter
			disableColumnSelector
			disableColumnMenu
			hideFooter
		/>
	);
};
