import React from 'react';
import { Box, Button, Skeleton } from '@mui/material';
import { Add } from '@mui/icons-material';
import { determineContext } from '@/hooks/determineContext';
import { useNavigate } from 'react-router-dom';
import { paths } from '@/app/routes';

export const AddNewClaimButton = () => {
	const { client, loading } = determineContext();
	const navigate = useNavigate();

	return (
		<div style={{ display: 'flex', justifyContent: 'right', paddingBottom: '1em' }}>
			{loading.client ? (
				<Skeleton variant='text' animation='wave' sx={{ width: '10em', height: '3em' }} />
			) : (
				<Button
					component='label'
					variant='tab_header'
					startIcon={<Add />}
					onClick={() => {
						navigate(paths.createClaim, { state: { client } });
					}}
				>
					{'Create claim'}
				</Button>
			)}
		</div>
	);
};
