import React, { useState, useMemo } from 'react';
import {
	Grid,
	Stack,
	FormLabel,
	MenuItem,
	Select,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { formatDate, taskPriorities } from '@/utils/constants';
import {
	useDeleteTaskMutation,
	useUpdateTaskMutation,
} from '@/features/engagements/engagementsApi';
import { useGetOwnerQuery } from '@/features/user/ownerApi';
import { ConfirmDialog } from '@/components/dialogs/ConfirmDialog';
import { getUnixTime, isAfter, isValid } from 'date-fns';
import { theme } from '@/app/theme';
import '@/styles/index.scss';
import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import { determineContext } from '@/hooks/determineContext';
import { ChangeAssignee } from '../cards/DealCardValues/ChangeAssigneeField';
import { TaskSubjectUpdateField } from '../cards/TaskCardValues/UpdateTaskSubjectField';
import EngagementsAccordion from '../cards/EngagementsAccordion';
import TaskCheckbox from './TaskCheckbox';

const minDate = new Date();

export const TaskAccordion = ({ task }) => {
	const { deal, objectType, client, contact } = determineContext();

	const objectId = (objectType === 'client' ? client : objectType === 'contact' ? contact : deal)
		?.hs_object_id;

	const location = useLocation();
	
	const openedTask = useMemo(() => {
		if (location.state?.task) {
			return location.state.task == task?.id;
		}
		return false;
	}, [location?.state?.task]);

	const [updateTask, { isLoading, isSuccess, isError }] = useUpdateTaskMutation();
	const [
		deleteTask,
		{ isLoading: isDeleteLoading, isSuccess: isDeleteSuccess, isError: isDeleteError },
	] = useDeleteTaskMutation();

	const { data: owner } = useGetOwnerQuery({ ownerId: task.ownerId }, { skip: !task.ownerId });

	const minimalInputTheme = theme.components.MuiInputBase.styleOverrides.minimal;
	const minimalLabelTheme = theme.components.MuiFormLabel.styleOverrides.minimal;

	const dueDate = useMemo(() => new Date(task.due), [task.due]);
	const lastUpdated = useMemo(() => new Date(task.lastUpdated), [task.lastUpdated]);

	const [dialogOpen, setDialogOpen] = useState(false);

	const handleOpenDialog = () => setDialogOpen(true);
	const handleCloseDialog = () => setDialogOpen(false);

	const handleConfirmDialog = async () => {
		handleCloseDialog();
		await archiveTask();
	};

	const getFullName = () => {
		return owner ? `${owner.firstName} ${owner.lastName}` : 'Unknown';
	};

	const completeTask = async () => {
		await updateTask({
			dealId: objectId,
			taskId: task.id,
			objectType,
			properties: {
				hs_task_status: task.completed ? 'NOT_STARTED' : 'COMPLETED',
			},
		}).unwrap();
	};

	const archiveTask = async () => {
		await deleteTask({
			dealId: objectId,
			taskId: task.id,
			objectType,
		});
	};

	const updateTaskProperties = async ({ priority, dueDate }) => {
		const properties = {
			...(dueDate && { hs_timestamp: `${getUnixTime(dueDate) * 1000}` }),
			...(priority && { hs_task_priority: priority.toUpperCase() }),
		};

		await updateTask({
			dealId: objectId,
			taskId: task.id,
			objectType,
			properties,
		}).unwrap();
	};

	return (
		<>
			<EngagementsAccordion
				title={
					<>
						<span style={{ fontWeight: 'bold' }}>Task</span> assigned to{' '}
						<span>{getFullName()}</span>
						<span style={{ margin: '0 0.5em' }}>{' | '}</span>
						<span style={{ fontWeight: '500' }}>Due {formatDate(dueDate, false)}</span>
					</>
				}
				expandedByDefault={openedTask}
				engagement={task}
				lastUpdated={lastUpdated}
				loading={isLoading || isDeleteLoading}
				handleDelete={handleOpenDialog}
				contentPrefix={
					<TaskCheckbox task={task} isLoading={isLoading} completeTask={completeTask} />
				}
				content={
					<TaskSubjectUpdateField title={task.subject} task={task} isSubject={true} />
				}
				expandedContent={
					<Stack>
						<TaskSubjectUpdateField
							title={parse(task.body ?? '')}
							task={task}
							isSubject={false}
						/>
						<Grid container spacing={2} alignItems='center'>
							<Grid item xs={3}>
								<FormLabel sx={minimalLabelTheme}>Priority</FormLabel>
								<Select
									name='priority'
									className='minimal-input'
									value={task.priority}
									size='small'
									disabled={isLoading}
									onChange={(e) =>
										updateTaskProperties({ priority: e.target.value })
									}
									fullWidth
									sx={minimalInputTheme}
									variant='standard'
								>
									{taskPriorities.map((value) => (
										<MenuItem
											value={value}
											key={`task-priority-selection-${value}`}
										>
											{value}
										</MenuItem>
									))}
								</Select>
							</Grid>
							<Grid item xs={3}>
								<ChangeAssignee task={task} />
							</Grid>
							<Grid item xs={3}>
								<FormLabel sx={minimalLabelTheme}>Due date</FormLabel>
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<DatePicker
										name='dueDate'
										className='minimal-input'
										disablePast
										disabled={isLoading}
										format='dd/MM/yyyy'
										value={dueDate}
										onChange={(value) => {
											const newDate =
												!isValid(value) || isAfter(minDate, value)
													? minDate
													: value;
											updateTaskProperties({ dueDate: newDate });
										}}
										slotProps={{
											textField: {
												size: 'small',
												sx: minimalInputTheme,
												variant: 'standard',
											},
										}}
										sx={minimalInputTheme}
									/>
								</LocalizationProvider>
							</Grid>
						</Grid>
					</Stack>
				}
			/>
			<ConfirmDialog
				openDialog={dialogOpen}
				handleClose={handleCloseDialog}
				handleConfirm={handleConfirmDialog}
				actionName={'delete'}
			/>
		</>
	);

	// return (
	// 	<Accordion
	// 		key={`activity-tab-task-${index}`}
	// 		expanded={openedTask}
	// 		onChange={handleTaskOpened}
	// 		sx={{
	// 			marginBottom: '12px',
	// 			borderRadius: '4px',
	// 			boxShadow: baseTheme.shadows[1],
	// 		}}
	// 	>
	// 		<Stack>
	// 			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
	// 				<AccordionSummary
	// 					expandIcon={
	// 						<ArrowForwardIosSharpIcon
	// 							sx={{ fontSize: '0.9rem', color: folioBlue }}
	// 						/>
	// 					}
	// 					aria-controls="panel1bh-content"
	// 					id="panel1bh-header"
	// 					sx={{
	// 						flexDirection: 'row-reverse',
	// 						width: '100%',
	// 						marginBottom: '-1rem',
	// 						'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
	// 							transform: 'rotate(90deg)',
	// 						},
	// 					}}
	// 				>
	// 					<Typography variant="task_body" sx={{ marginLeft: '0.5em' }}>
	// 						<span style={{ fontWeight: 'bold' }}>Task</span> assigned to{' '}
	// 						<span>{getFullName()}</span>
	// 						<span style={{ margin: '0 0.5em' }}>{' | '}</span>
	// 						<span style={{ color: '#505AFC' }}>
	// 							Due {formatDate(dueDate, false)}
	// 						</span>
	// 						{objectType === 'client' && dealParams.id !== null && (
	// 							<span><span style={{ margin: '0 0.5em' }}>{' | '}</span>
	// 								{`${dealParams?.name}`}
	// 							</span>
	// 						)}
	// 					</Typography>
	// 				</AccordionSummary>
	// 				<div
	// 					style={{
	// 						marginTop: '12px',
	// 						display: 'flex',
	// 						alignItems: 'center',
	// 						justifyContent: 'right',
	// 					}}
	// 				>
	// 					{(task.priority !== 'LOW' && task.priority !== 'NONE') && <PriorityText task={task} />}
	// 					{isDeleteLoading || isLoading ? (
	// 						<Box padding="0.76rem">
	// 							<CircularProgress size="1rem" />
	// 						</Box>
	// 					) : (
	// 						<IconButton
	// 							aria-label="delete"
	// 							sx={{ fontSize: '0.5rem' }}
	// 							onClick={handleOpenDialog}
	// 						>
	// 							<Delete />
	// 						</IconButton>
	// 					)}
	// 				</div>
	// 			</div>
	// 			<Stack
	// 				direction="row"
	// 				alignItems="center"
	// 				sx={{ marginLeft: '1.5em' }}
	// 			>
	// 				{isLoading ? (
	// 					<Box padding="0.76rem">
	// 						<CircularProgress size="1rem" />
	// 					</Box>
	// 				) : (
	// 					<Checkbox
	// 						icon={
	// 							<>
	// 								<svg width={0} height={0}>
	// 									<linearGradient
	// 										id="linearColors"
	// 										x1={0}
	// 										y1={1}
	// 										x2={1}
	// 										y2={1}
	// 									>
	// 										<stop offset={0} stopColor="rgba(131,214,255,1)" />
	// 										<stop offset={1} stopColor="rgba(229,132,255,1)" />
	// 									</linearGradient>
	// 								</svg>
	// 								<CheckCircleOutlineRoundedIcon
	// 									sx={{ fill: 'url(#linearColors)' }}
	// 								/>
	// 							</>
	// 						}
	// 						checkedIcon={
	// 							<>
	// 								<svg width={0} height={0}>
	// 									<linearGradient
	// 										id="linearColors2"
	// 										x1={0}
	// 										y1={1}
	// 										x2={1}
	// 										y2={1}
	// 									>
	// 										<stop offset={0} stopColor="rgba(131,214,255,1)" />
	// 										<stop offset={1} stopColor="rgba(229,132,255,1)" />
	// 									</linearGradient>
	// 								</svg>
	// 								<CheckCircleRoundedIcon
	// 									sx={{ fill: 'url(#linearColors2)' }}
	// 								/>
	// 							</>
	// 						}
	// 						checked={task.completed}
	// 						onChange={completeTask}
	// 					/>
	// 				)}
	// 				<TaskSubjectUpdateField title={task.subject} task={task} isSubject={true} />
	// 				{/* <Typography
	// 					onClick={() => handleTaskOpened(index)}
	// 					variant="task_body"
	// 					sx={{ marginLeft: '3px', marginRight: '1.5em', cursor: 'pointer' }}
	// 					style={{
	// 						textDecoration: task.completed ? 'line-through' : 'none',
	// 						width: '100%',
	// 					}}
	// 				>
	// 					{task.subject}
	// 				</Typography> */}
	// 			</Stack>
	// 			<div
	// 				style={{ display: 'flex', justifyContent: 'right', padding: '10px' }}
	// 			>
	// 				<Typography variant="task_updated">
	// 					{`Last updated: ${formatDate(lastUpdated, true)}`}
	// 				</Typography>
	// 			</div>
	// 		</Stack>
	// 		<AccordionDetails sx={{ borderTop: '1px solid rgba(0, 0, 0, .125)', margin: '0 1.5em 1.5em' }}>
	// 			<Stack width="100%">
	// 				<TaskSubjectUpdateField title={parse(task.body ?? '')} task={task}  isSubject={false}/>
	// 				<Grid container spacing={2} alignItems="center">
	// 					<Grid item xs={3}>
	// 						<FormLabel sx={minimalLabelTheme}>Priority</FormLabel>
	// 						<Select
	// 							name="priority"
	// 							className="minimal-input"
	// 							value={task.priority}
	// 							size="small"
	// 							disabled={isLoading}
	// 							onChange={(e) =>
	// 								updateTaskProperties({ priority: e.target.value })
	// 							}
	// 							fullWidth
	// 							sx={minimalInputTheme}
	// 							variant="standard"
	// 						>
	// 							{taskPriorities.map((value) => (
	// 								<MenuItem
	// 									value={value}
	// 									key={`task-priority-selection-${value}`}
	// 								>
	// 									{value}
	// 								</MenuItem>
	// 							))}
	// 						</Select>
	// 					</Grid>
	// 					<Grid item xs={3}>
	// 						<ChangeAssignee task={task} />
	// 					</Grid>
	// 					<Grid item xs={3}>
	// 						<FormLabel sx={minimalLabelTheme}>Due date</FormLabel>
	// 						<LocalizationProvider dateAdapter={AdapterDateFns}>
	// 							<DatePicker
	// 								name="dueDate"
	// 								className="minimal-input"
	// 								disablePast
	// 								disabled={isLoading}
	// 								format="dd/MM/yyyy"
	// 								value={dueDate}
	// 								onChange={(value) => {
	// 									const newDate = !isValid(value) || isAfter(minDate, value)
	// 										? minDate
	// 										: value;
	// 									updateTaskProperties({ dueDate: newDate });
	// 								}}
	// 								slotProps={{
	// 									textField: {
	// 										size: 'small',
	// 										sx: minimalInputTheme,
	// 										variant: 'standard',
	// 									},
	// 								}}
	// 								sx={minimalInputTheme}
	// 							/>
	// 						</LocalizationProvider>
	// 					</Grid>
	// 				</Grid>
	// 				{/* {objectType === 'client' && dealParams.id !== null && (
	// 					<Link
	// 						to={dealLink()}
	// 						component={RouterLink}
	// 						sx={{ textDecoration: 'none' }}
	// 					>
	// 						<Typography variant='blue_text'>
	// 								View record keeping
	// 						</Typography>
	// 					</Link>
	// 				)} */}
	// 			</Stack>

	// 		</AccordionDetails>
	// 		{
	// 			<ConfirmDialog
	// 				openDialog={dialogOpen}
	// 				handleClose={handleCloseDialog}
	// 				handleConfirm={handleConfirmDialog}
	// 				actionName={'delete'}
	// 			/>
	// 		}
	// 	</Accordion>
	// );
};
