import React from 'react';
import { TextField, FormLabel } from '@mui/material';
import { formatKey } from '@/utils/constants';
import { NumericFormat } from 'react-number-format';

export const FormTextField = ({ field, formKey, value, formik, disabled }) => {
	const formikKey = `${field}.${formKey}`;

	return (
		<>
			<FormLabel>{formatKey(formKey)}</FormLabel>
			{value.type === 'currency' || value.type === 'numeric' ? (
				<NumericFormat
					{...(value.type === 'currency' && { prefix: '$' })}
					decimalScale={0}
					fixedDecimalScale
					thousandSeparator
					allowNegative={false}
					customInput={TextField}
					fullWidth
					value={formik.values[formikKey]}
					onValueChange={({ floatValue }) => {
						formik.setFieldValue(formikKey, floatValue);
					}}
					disabled={disabled}
					name={formikKey}
				/>
			) : (
				<TextField
					fullWidth
					{...(value.lines && {
						multiline: true,
						rows: value.lines,
					})}
					name={formikKey}
					{...formik.getFieldProps(formikKey)}
					disabled={disabled}
				/>
			)}
		</>
	);
};
