import React, { useMemo, useState } from 'react';
import { Typography, Skeleton, Select, MenuItem, Stack, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { determineContext } from '@/hooks/determineContext';
import { theme } from '@/app/theme';
import { dealsApi, useUpdateDealPropertiesMutation } from '@/features/deals/dealsApi';
import {
	ticketsApi,
	useRetrieveClaimStatusListQuery,
	useUpdateInsightClaimMutation,
	useUpdateTicketPropertiesMutation,
} from '@/features/claims/ticketsApi';
import { setLoading } from '@/features/engagements/engagementsSlice';
import { engagementsApi } from '@/features/engagements/engagementsApi';
import { ConfirmDialog } from '@/components/dialogs/ConfirmDialog';

export const DealCardStageChange = ({ showLabel, title, minimal = true, showReopen = false }) => {
	const dispatch = useDispatch();
	const { deal, claim, objectType, loading, pipelines } = determineContext();
	const loadingDeal = loading?.deal;

	const isDeal = objectType === 'deal';
	const isClaim = deal?.hs_pipeline == process.env.REACT_APP_PIPELINE_CLAIMS;

	const [showDialog, setShowDialog] = useState(false);

	const { data: statusList } = useRetrieveClaimStatusListQuery(null, { skip: !isClaim });

	const minimalInputTheme = theme.components.MuiInputBase.styleOverrides.minimal;
	const minimalLabelTheme = theme.components.MuiFormLabel.styleOverrides.minimal;

	const dealStage = useMemo(() => {
		const stageId = deal?.dealstage ?? deal?.hs_pipeline_stage;
		const stage = pipelines?.stages?.find((stage) => stage.id === stageId);
		return stage;
	}, [pipelines, deal?.hs_pipeline_stage, deal?.dealstage, pipelines?.stages]);

	const dealStageId = useMemo(() => dealStage?.id, [dealStage]);

	const isDisabled = useMemo(
		() =>
			dealStage?.metadata?.probability?.toString() === '1.0' ||
			dealStage?.metadata?.ticketState === 'CLOSED',
		[dealStage]
	);

	const [updateDealProperties, { isLoading }] = isDeal
		? useUpdateDealPropertiesMutation()
		: useUpdateTicketPropertiesMutation();

	const [
		updateInsightClaim,
		{ isLoading: isLoadingClaimUpdate },
	] = useUpdateInsightClaimMutation();

	const showReopenButton = showReopen && isClaim && claim && isDisabled && statusList && !isLoadingClaimUpdate;

	const reopenClaim = async () => {
		setShowDialog(false);
		
		const newStage = (pipelines?.stages ?? []).find(s => (s.label ?? '').toLowerCase().includes('client'));
		const newStatus = (statusList ?? []).find(s => (s.Description ?? '').toLowerCase().includes('awaiting info'));
		console.log('NEW STAGE', newStage, 'NEW STATUS', newStatus);

		if (newStage && newStatus) {
			await handleDealStageChange({ target: { value: newStage.id }});
			await updateInsightClaim({
				claimId: claim.Id,
				properties: {
					StatusId: newStatus.Id,
				},
			});
		}
	};

	const handleDealStageChange = async (event) => {
		const selectedStageId = event.target.value;

		const params = {
			[isDeal ? 'dealstage' : 'hs_pipeline_stage']: selectedStageId,
		};

		const response = await updateDealProperties({
			[isDeal ? 'dealId' : 'ticketId']: deal.hs_object_id,
			body: params,
		}).unwrap();
		// invalidateTags();
		console.log('👾 ~ transform update deal response', response);
	};

	// const handleMoveDealNextStage = async (e) => {
	// 	e.preventDefault();

	// 	try {
	// 		const currentStageIndex = pipelines?.stages.findIndex(
	// 			(stage) => stage.id === deal?.hs_pipeline_stage || stage.id === deal?.dealstage
	// 		);
	// 		if (
	// 			currentStageIndex !== -1 &&
	//     currentStageIndex < pipelines?.stages?.length - 1
	// 		) {
	// 			console.log('this is a valid stage');
	// 			const nextStageId =
	//       pipelines?.stages[currentStageIndex + 1].id;
	// 			console.log('here is the next stage id: ', nextStageId);

	// 			const params = {
	// 				[isDeal ? 'dealstage' : 'hs_pipeline_stage']: nextStageId,
	// 			};
	// 			const response = await updateDealProperties({
	// 				[isDeal ? 'dealId' : 'ticketId']: deal.hs_object_id,
	// 				body: params,
	// 			}).unwrap();
	// 			console.log('👾 ~ transform update ticket response', response);
	// 		} else {
	// 			console.log('invalid stage');
	// 		}
	// 	} catch (err) {
	// 		console.log('🙅 ~ Error moving ticket status', err);
	// 	}
	// };

	return (
		<Stack width={'100%'}>
			{showLabel != false && (
				<Typography sx={minimalLabelTheme}>{title ?? 'Deal Stage'}</Typography>
			)}
			{loadingDeal ? (
				<Skeleton animation='wave' width='100%' height='2em' />
			) : (
				<Select
					fullWidth
					value={dealStageId}
					onChange={handleDealStageChange}
					disabled={isLoading || isDisabled || isLoadingClaimUpdate}
					variant={minimal ? 'standard' : 'outlined'}
					sx={minimal === true ? minimalInputTheme : {}}
				>
					{pipelines?.stages.map((stage, index) => {
						const itemDisabled =
							stage?.metadata?.probability?.toString() === '1.0' ||
							stage?.metadata?.ticketState === 'CLOSED';
						return (
							<MenuItem key={index} value={stage.id} disabled={itemDisabled}>
								{stage.label}
							</MenuItem>
						);
					})}
				</Select>
			)}
			{showReopenButton && (
				<Stack direction='row' justifyContent={'flex-end'}>
					<Button size='small' variant='text' onClick={() => setShowDialog(true)}>
						Reopen Claim
					</Button>
				</Stack>
			)}
			{showDialog && (
				<ConfirmDialog
					openDialog={showDialog}
					handleClose={() => setShowDialog(false)}
					handleConfirm={reopenClaim}
					actionName={'reopen claim'}
					canBeUndone={true}
				/>
			)}
		</Stack>
	);
};
