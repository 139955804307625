import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
	TableCell,
	TableRow,
	Typography,
	Stack,
	IconButton,
	Menu,
	MenuItem,
	Link,
	ListItemIcon,
	ListItemText,
    Skeleton,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { BlinkingCircle } from '../icons/BlinkingCircle';
import { Delete } from '@mui/icons-material';
import {
	closedPipelineStages,
	folioBlue,
	formatDateString,
	getUrlFromPipeline,
} from '@/utils/constants';
import { useGetPipelinePropertiesQuery } from '@/features/pipelines/pipelineApi';
import { ClaimsIcon } from '../icons/ClaimsIcon';
import { paths } from '@/app/routes';
import ClientContext from '@/context/clientContext';
import ArticleIcon from '@mui/icons-material/Article';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { RefreshIcon } from '@/components/icons/RefreshIcon';
import { formatCurrency } from '@/utils/constants';
import { ConfirmDialog } from '../dialogs/ConfirmDialog';
import { useDeleteDealMutation } from '@/features/deals/dealsApi';
import { showToast } from '@/features/toast/toastSlice';
import { useDispatch } from 'react-redux';
import { isAfter } from 'date-fns';


export const PolicyTransactionRow = ({ transaction, deal, policy }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const { client } = useContext(ClientContext);

	const dealId = deal?.id ?? deal?.properties?.hs_object_id;

	const objectType = deal?.properties?.pipeline != null ? 'deal' : 'ticket';

	const open = Boolean(anchorEl);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const pipeline = deal?.properties?.pipeline ?? deal?.properties?.hs_pipeline;

	const [showDialog, setShowDialog] = useState(false);

	const [deleteDeal, { isLoading }] = useDeleteDealMutation({
		fixedCacheKey: `delete-deal-${dealId}-${objectType}`,
	});

	const handleClick = (event) => setAnchorEl(event.currentTarget);

	const handleClose = () => setAnchorEl(null);

	const pipelineQuery = useGetPipelinePropertiesQuery(pipeline, {
		skip: !pipeline,
	});

	const handleClaim = () => {
		navigate(paths.createClaim, { state: { client, policy, transaction } });
		handleClose();
	};

	const handleUpdate = () => {
		navigate(paths.createEndorsement, {
			state: { client, policy, transaction },
		});
		handleClose();
	};

	const dealLink = useMemo(() => {
		const path = getUrlFromPipeline(pipeline);
		return `${path}/${dealId}`;
	}, [deal, pipeline]);

	const getMenuLink = () => {
		return (
			<>
				<IconButton aria-label='edit' onClick={handleClick}>
					<MoreVertIcon />
				</IconButton>
				<Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
					<MenuItem
						onClick={() => {
							handleClose();
							navigate(dealLink);
						}}
					>
						<ListItemIcon>
							<ArticleIcon sx={{ color: folioBlue }}/>
						</ListItemIcon>
						<ListItemText sx={{ color: folioBlue }}>View record keeping</ListItemText>
					</MenuItem>
					{transaction != null && (
						<MenuItem onClick={handleClaim}>
							<ListItemIcon>
								<ClaimsIcon sx={{ color: folioBlue, fontSize: '1.25em', ml: '4px' }}/>
							</ListItemIcon>
							<ListItemText sx={{ color: folioBlue }}>Claim</ListItemText>
						</MenuItem>
					)}
					{transaction != null && (
						<MenuItem onClick={handleUpdate}>
							<ListItemIcon>
								<RefreshIcon sx={{ color: folioBlue, fontSize: '1.25em', ml: '4px'  }}/>
							</ListItemIcon>
							<ListItemText sx={{ color: folioBlue }}>Endorse</ListItemText>
						</MenuItem>
					)}
					<MenuItem
						onClick={() => {
							setShowDialog(true);
							handleClose();
						}}
					>
						<ListItemIcon>
							<Delete />
						</ListItemIcon>
						<ListItemText sx={{ fontWeight: 'bold' }}>Delete deal</ListItemText>
					</MenuItem>
					{transaction != null && (
						<MenuItem>
							<Typography
								color='gray'
								fontSize='12px'
							>{`Transaction ID: ${transaction?.Id}`}</Typography>
						</MenuItem>
					)}
					{policy != null && (
						<MenuItem>
							<Typography
								color='gray'
								fontSize='12px'
							>{`Policy ID: ${policy?.Id}`}</Typography>
						</MenuItem>
					)}
				</Menu>
			</>
		);
	};

	const handleDeleteConfirmation = async () => {
		setShowDialog(false);
		const path = getUrlFromPipeline(deal?.pipeline ?? deal?.hs_pipeline);

		await deleteDeal({ dealId, objectType })
			.unwrap()
			.then((res) => {
				dispatch(
					showToast({
						message: `Deal ${dealId} successfully deleted.`,
						success: true,
					})
				);
				navigate(path);
			})
			.catch((err) => {
				console.log(`Error deleting deal ${dealId}`, err);
				dispatch(
					showToast({
						message: 'Error deleting deal; please try again.',
						error: true,
					})
				);
			});
	};

    const isInactive = useMemo(() => {
        const today = new Date();
        const isLapsed = transaction?.ToDate ? isAfter(today, new Date (transaction?.ToDate)) : false;
        return isLapsed || transaction?.IsClosed || !transaction?.IsLatestInPeriod;
    }, [transaction?.IsLatestInPeriod, transaction?.IsClosed, transaction?.ToDate]);

	return (
		<>
			<TableRow 
				key={`table-row-${deal?.id ?? deal?.hs_object_id}`} 
				selected={showDialog}
				sx={{
					'&.Mui-selected': {
						color: 'rgba(0, 0, 0, 0.9) !important',
						'& .MuiTypography-root': {
							color: 'rgba(0, 0, 0, 0.9) !important',
						}
					}
				}}>
				<TableCell>
					<Stack direction='column' spacing={0.5} width='100%'>
						{isLoading ? <Skeleton variant='text' /> : <Typography variant='subtitle2'>
							{policy?.Description ??
								deal?.properties?.dealname ??
								deal.properties?.subject ??
								'-'}
						</Typography>}
						{isLoading ? <Skeleton variant='text' /> : <Typography>
							{transaction?.PolicyNumber ??
								deal?.properties?.description ??
								deal?.properties.content ??
								'-'}
						</Typography>}
					</Stack>
				</TableCell>
				<TableCell width={'20%'} sx={{ whiteSpace: 'nowrap' }}>
					<Stack direction='column' spacing={1} width='100%'>
						{isLoading ? <Skeleton variant='text' /> : <Typography variant='subtitle2'>
							{transaction?.FromDate ? formatDateString(transaction?.FromDate) : '-'}
						</Typography>}
						{isLoading ? <Skeleton variant='text' /> : <Typography variant='subtitle2'>
							{transaction?.ToDate ? formatDateString(transaction?.ToDate) : '-'}
						</Typography>}
					</Stack>
				</TableCell>
				<TableCell>
					<Stack direction='column' spacing={1} width='100%'>
						{isLoading ? <Skeleton variant='text' /> : <Typography variant='subtitle2'>
							{transaction?.TransactionType ??
								pipelineQuery?.data?.pipelines?.label ??
								'-'}
						</Typography>}
						{isLoading ? <Skeleton variant='text' /> : <Typography
							variant={transaction ? 'subtitle2' : 'body1'}
							{...(!transaction && { fontSize: '12px', color: 'gray' })}
						>
							{transaction
								? formatCurrency(transaction?.AnnualTotalGrossPremium ?? 0)
								: 'No transaction linked'}
						</Typography>}
					</Stack>
				</TableCell>
				<TableCell>
					{isLoading ? <Skeleton variant='text' /> : <Stack direction='row' alignItems='center'>
						<BlinkingCircle
							fontSize='6px'
							color={
								transaction
									? !isInactive
										? '#00C650'
										: '#FF0000'
									: '#1ABCFE'
							}
						/>
						<Typography style={{ paddingLeft: '5px' }}>
							{transaction
								? !isInactive
									? 'Active'
									: 'Inactive'
								: 'In progress'}
						</Typography>
					</Stack>}
				</TableCell>
				<TableCell>{isLoading ? <></> : getMenuLink()}</TableCell>
			</TableRow>
			{showDialog && (
				<ConfirmDialog
					openDialog={showDialog}
					handleClose={() => setShowDialog(false)}
					handleConfirm={handleDeleteConfirmation}
					actionName={'delete'}
				/>
			)}
		</>
	);
};
