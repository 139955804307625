/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useMemo } from 'react';
import {
	Typography,
	Grid,
	Stack,
	TextField,
	CircularProgress,
	Box,
	Skeleton,
	FormControl,
	FormLabel,
} from '@mui/material';
import { formatKey } from '@/utils/constants';
import { determineContext } from '@/hooks/determineContext';
import { factFindApi, useCreateDealProposalMutation } from '@/features/factFind/factFindApi';
import { useDispatch, useSelector } from 'react-redux';
import { setEdit, setSubmit } from '@/features/editButton/editButtonSlice';
import Robot from '@/assets/images/ai-assistant.gif';
import { useFormik } from 'formik';
import { DetailsAccordion } from '@/components/forms/CreateSOAForm/fields/DetailsAccordion';

export const FactFindForm = ({ title, factFindType, latestDoc }) => {
	const { deal, loading, soas } = determineContext();

	const dispatch = useDispatch();

	const editState = useSelector((state) => state.editButton.edit);
	const hasSubmitted = useSelector((state) => state.editButton.submit[factFindType]);
	const isEditing = editState[factFindType];

	useEffect(() => {
		dispatch(setEdit({ key: factFindType, value: false }));
	}, []);

	const isGeneratingSOA = useMemo(() => soas.length > 0 && soas[0]?.downloadUrl == null, [soas]);

	const isError = latestDoc?.error;
	const isGenerating = latestDoc?.generating || isGeneratingSOA;
	

	const isObject = (value) => value != null && typeof value === 'object' && !Array.isArray(value);

	const createEmptyStructure = (fields) => {
		const result = {};

		console.log('FIELDS', fields);
		Object.entries(fields ?? {}).forEach(([key, value]) => {
			console.log('VALUE', value, typeof value);
			if (isObject(value)) {
				result[key] = Object.keys(value).reduce((acc, innerKey) => {
					console.log('Inner value', fields[key][innerKey], typeof fields[key][innerKey]);
					if (isObject(fields[key][innerKey])) {
						acc[innerKey] = createEmptyStructure(fields[key][innerKey]);
					} else {
						acc[innerKey] = (fields[key][innerKey] ?? '').toString();
					}
					return acc;
				}, {});
			} else {
				if (value != null && Array.isArray(value)) {
					result[key] = value.join(', ');
				} else {
					result[key] = (value ?? '').toString();
				}
			}
		});

		return result;
	};

	const initialValues = useMemo(
		() => createEmptyStructure(latestDoc?.jsonData ?? {}),
		[latestDoc?.jsonData]
	);

	const formik = useFormik({
		initialValues,
		// validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { resetForm, setFieldValue, setSubmitting }) => {
			console.log('📋 ~ Formik values', {
				values,
			});
			const body = {
				dealId: deal.hs_object_id,
				threadId: latestDoc.threadId,
				jsonData: values,
				type: latestDoc.type,
				docId: latestDoc.id,
			};

			await createDealProposal(body)
				.unwrap()
				.then(async (res) => {
					console.log('🚀 ~ Created Proposal', res);
				})
				.catch((err) => {
					console.log('🙅 ~ Error creating Proposal', err);
				})
				.finally(() => {
					dispatch(setEdit({ key: factFindType, value: false }));
					dispatch(setSubmit({ key: factFindType, value: false }));
				});
			setSubmitting(false);
		},
	});

	useEffect(() => {
		let intervalId;

		const checkRunStatus = async () => {
			dispatch(
				factFindApi.util.invalidateTags([
					{ type: 'DEAL_NOTE_SUMMARY', id: deal?.hs_object_id },
				])
			);
		};

		if (isGenerating) {
			intervalId = setInterval(checkRunStatus, 10000); // Check every 10 seconds
		}

		return () => clearInterval(intervalId);
	}, [isGenerating, deal?.hs_object_id]);

	const [createDealProposal, { isLoading }] = useCreateDealProposalMutation();

	const loadingInProgress = isLoading || loading.deal || loading.aiDocs;

	useEffect(() => {
		if (hasSubmitted) {
			formik.submitForm();
		} else if (!isEditing) {
			formik.resetForm();
		}
	}, [isEditing, hasSubmitted]);

	const renderFormControl = (field, i) => {
		const fieldArray = field.split('.');
		const fieldName = fieldArray[fieldArray.length - 1];
		const isEndorsementField = fieldName.trim().includes('endorsement');
		return (
			<FormControl
				sx={{ '& .MuiInputBase-root': { p: 0 } }}
				key={`factfind-field-${i}-${field}`}
			>
				<Grid container>
					<Grid item sm={12} md={3}>
						<FormLabel component='legend'>{formatKey(fieldName)}</FormLabel>
					</Grid>
					<Grid item sm={12} md={9}>
						{loadingInProgress || isGenerating ? (
							<Skeleton width='100%' />
						) : (
							<TextField
								variant='standard'
								className='minimal-input'
								{...formik.getFieldProps(field)}
								fullWidth
								InputProps={{
									readOnly: !isEditing,
								}}
								inputProps={{
									...(!isEditing && { style: { cursor: 'not-allowed' } }),
								}}
								{...(isEndorsementField && {
									multiline: true,
									minRows: 2,
									maxRows: 5,
								})}
							/>
						)}
					</Grid>
				</Grid>
			</FormControl>
		);
	};

	if (loadingInProgress && !latestDoc) {
		return (
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					width: '100%',
					alignItems: 'center',
					pt: '0.5em',
				}}
			>
				<Skeleton animation='wave' height='15px' width='100%' />
			</Box>
		);
	}

	if (!latestDoc?.jsonData && !isGenerating) {
		return (
			<Typography variant='subtitle2' mt='1em'>
				No information available. Upload a document to generate.
			</Typography>
		);
	}

	if (isError) {
		return (
			<Typography variant='subtitle2' mt='1em'>
				There was an error extracting info from uploaded document. Please try again.
			</Typography>
		);
	}

	return (
		<Box pr='0.5em'>
			<Typography variant='subtitle2' sx={{ fontWeight: 'bold', fontSize: '20px' }}>
				{`${title} details`}
			</Typography>
			<Stack spacing={1}>
				{isGenerating && (
					<>
						<Box
							sx={{
								position: 'absolute',
								top: '50%',
								left: '41%',
								borderRadius: '50%',
								zIndex: 5,
								height: '150px',
								width: '150px',
								backgroundImage: `url(${Robot})`,
								backgroundPosition: 'left',
								backgroundSize: 'cover',
								backgroundRepeat: 'no-repeat',
								display: 'flex',
								flexDirection: 'column',
							}}
						/>
						<Stack direction='row' spacing={1} alignItems='center' pb='1em'>
							<CircularProgress size={'1.25em'} />
							<Typography>{`Generating ${title.toLowerCase()} data. Please check back soon.`}</Typography>
						</Stack>
					</>
				)}
				{Object.keys(formik.values).map((field, i) => {
					return typeof formik.values[field] === 'object' ? (
						<Stack spacing={1} key={`factfind-field-${i}-${field}`}>
							<Typography
								variant='subtitle2'
								sx={{ fontWeight: 'bold', fontSize: '15px' }}
							>
								{formatKey(field)}
							</Typography>
							<Stack spacing={1}>
								{Object.keys(formik.values[field]).map((objectField, index) => {
									return typeof formik.values[field][objectField] === 'object' ? (
										<DetailsAccordion
											title={formatKey(objectField)}
											key={`factfind-field-${index}-${objectField}`}
											titleProps={{
												sx: {
													fontSize: '14px',
													fontWeight: 800,
												},
											}}
											sx={{
												backgroundColor: 'transparent',
												'&:not(:last-child)': {
													borderBottom: 0,
												},
												'&::before': {
													display: 'none',
												},
												paddingBottom: 0,
												'.MuiAccordionSummary-root': { padding: 0 },
											}}
										>
											<Stack spacing={2}>
												{Object.keys(formik.values[field][objectField]).map(
													(subSubField, ind) => {
														if (
															typeof formik.values[field][
																objectField
															][subSubField] === 'object'
														) {
															return (
																<Stack
																	spacing={1}
																	key={`${field}.${objectField}.${subSubField}-${ind}`}
																>
																	<Typography variant='broker_header'>
																		{formatKey(subSubField)}
																	</Typography>
																	{Object.keys(
																		formik.values[field][
																			objectField
																		][subSubField]
																	)
																		.filter(
																			(k) =>
																				typeof formik
																					.values[field][
																						objectField
																					][subSubField][
																						k
																					] !== 'object'
																		)
																		.map(
																			(
																				subSubSubField,
																				indexx
																			) =>
																				renderFormControl(
																					`${field}.${objectField}.${subSubField}.${subSubSubField}`,
																					indexx
																				)
																		)}
																</Stack>
															);
														} else {
															return renderFormControl(
																`${field}.${objectField}.${subSubField}`,
																ind
															);
														}
													}
												)}
											</Stack>
										</DetailsAccordion>
									) : (
										renderFormControl(`${field}.${objectField}`, index)
									);
								})}
							</Stack>
						</Stack>
					) : (
						renderFormControl(field, i)
					);
				})}
			</Stack>
		</Box>
	);
};
