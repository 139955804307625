/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useMemo } from 'react';
import {
	Typography,
	Box,
	IconButton,
	Skeleton,
	Stack,
	Tooltip,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useSharepoint } from '@/hooks/useSharepoint';
import {
	setRows,
	setLoading,
	triggerRefresh,
	setSearchValue,
} from '@/features/table/attachmentsTableSlice';
import { DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid';
import {
	folioBlue,
	formatDate,
	specialChars,
	truncateFilename,
} from '@/utils/constants';
import { showToast } from '@/features/toast/toastSlice';
import { useAttachmentsContext } from '@/context/attachmentsContext';
import SearchField from '@/components/SearchField';
import PreviewIcon from '@mui/icons-material/Preview';
import { useMsGraph } from '@/hooks/useMsGraph';
import { getComparator, stableSort } from '@/components/table/functions';

const NoAttachmentsTable = () => (
	<DataGrid
		sx={{ backgroundColor: 'white' }}
		rows={[
			{
				id: 'no-attachments-message',
				Name: 'No attachments added.',
			},
		]}
		columns={[
			{
				field: 'no-attachments-message',
				headerName: 'DOCUMENTS',
				align: 'left',
				sortable: false,
				editable: false,
				width: 400,
				renderCell: (params) => (
					<span style={{ color: 'rgba(0,0,0,0.5)' }}>{params.row.Name}</span>
				),
			},
		]}
		disableColumnMenu
		disableRowSelectionOnClick
		disableSelectionOnClick
		disableColumnFilter
		disableColumnSelector
	/>
);

export const AttachmentsTable = ({ canDownload, editableView = false }) => {
	const dispatch = useDispatch();

	const { deal, client, files, sharepoint, isLoading, isError, dealRow } =
		useAttachmentsContext();

	// const rowData = useMemo(
	// 	() =>
	// 		deal && dealRow ? dealRow.dealFolderPath : client ? replaceFolderName(client.name) : '',
	// 	[deal, client, dealRow]
	// );
	const { getListItem } = useMsGraph();

	const {
		rowsPerPage,
		// orderBy,
		// order,
		page,
		rows,
		loading,
		error,
		// refreshIndicator,
		searchValue,
	} = useSelector((state) => state.attachmentsTable);

	const allLoading = loading || isLoading;
	const allError = error || isError;

	const columns = [
		...(canDownload != null && canDownload != false
			? [
				{
					field: 'webUrl',
					headerName: '',
					sortable: false,
					width: GRID_CHECKBOX_SELECTION_COL_DEF.width,
					renderCell: ({ row }) =>
						allLoading ? (
							<Skeleton width={'100%'} height={40} />
						) : (
							<IconButton
								color='primary'
								href={`${row.webUrl}?web=1`}
								target='_blank'
								rel='noopener noreferrer'
								sx={{ color: folioBlue }}
							>
								<PreviewIcon />
							</IconButton>
						),
				},
			  ]
			: []),
		{
			field: 'name',
			numeric: false,
			headerName: 'DOCUMENTS',
			editable: true,
			align: 'left',
			width: 350,
			renderCell: ({ row }) => {
				const name = truncateFilename(row?.name ?? '', 50);
				return allLoading ? (
					<Skeleton width={'100%'} height={40} />
				) : (
					<Tooltip title={'Double-click to edit file name'} placement='top' arrow>
						<Stack direction='column' spacing={0.2}>
							<Typography noWrap variant='body1'>
								{name}
							</Typography>
							<Typography variant='avatar_subtitle'>
								{row.directory ?? row.parentReference?.name ?? ''}
							</Typography>
							{specialChars.test(row.name) && (
								<Typography variant='avatar_subtitle' sx={{ color: 'red' }}>
									{'File name contains characters that could cause an error'}
								</Typography>
							)}
						</Stack>
					</Tooltip>
				);
			},
		},
		{
			field: 'type',
			sortable: true,
			headerName: 'TYPE',
			align: 'right',
			width: 85,
			renderCell: ({ row }) => {
				return allLoading ? (
					<Skeleton width={'100%'} height={40} />
				) : (
					<Typography noWrap variant='body1'>
						{row.type}
					</Typography>
				);
			},
		},
		{
			field: 'createdDateTimeUnix',
			sortable: true,
			headerName: 'DATE ADDED',
			align: 'right',
			width: 200,
			renderCell: ({ row }) =>
				allLoading ? (
					<Skeleton width={'100%'} height={40} />
				) : (
					<Typography noWrap variant='body1'>
						{row.createdDateTime ? formatDate(new Date(row.createdDateTime), true) : ''}
					</Typography>
				),
		},
		{
			field: 'Description',
			headerName: 'DESCRIPTION',
			width: 300,
			editable: true,
			renderCell: ({ row }) => {
				const empty = (row.Description ?? '').length === 0;
				return allLoading ? (
					<Skeleton width={'100%'} height={40} />
				) : (
					<span style={{ ...(empty && { color: 'rgba(0,0,0,0.25)' }) }}>
						{empty ? 'Please enter a description' : row.Description}
					</span>
				);
			},
		},
	];

	const { getSharepointFileMetadata, updateFileMetadata } = useSharepoint(
		sharepoint?.sharepointSite,
		sharepoint?.clientSiteSuffix,
		sharepoint?.clientFolder,
		sharepoint?.clientSite
	);

	const handleAddMetadataFileDocument = async (metadata, url) => {
		if (sharepoint?.isValid) {
			await updateFileMetadata(url, metadata);
		}
	};

	useEffect(() => {
		const updateFiles = async () => {
			dispatch(setLoading(true));
			await Promise.all(
				files.map(async (f) => {
					try {
						const listItem = await getListItem(f.parentReference.driveId, f.id);
						const url = listItem.webUrl.split('/sites/');
						const serverRelativeUrl = `/sites/${url[url.length - 1]}`;
						const metadata = await getSharepointFileMetadata(
							serverRelativeUrl
						);
						return {
							...f,
							Description: metadata?.OData__x005f_ExtendedDescription ?? '',
							metadata,
							serverRelativeUrl
						};
					} catch (error) {
						return f;
					}
				})
			).then(res => {
				dispatch(setRows(res));
			});
			dispatch(setLoading(false));
		};
		updateFiles();
	}, [files]);

	const displayedFiles = useMemo(() => {
		// const display = rows.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
		
		const search = searchValue.toLowerCase().trim();
		const filesToDisplay = search.length > 0
			? rows.filter(
				(file) =>
					file.name.toLowerCase().includes(search) ||
						file.Description.toLowerCase().includes(search)
			  )
			: rows;
		return stableSort(filesToDisplay, getComparator('desc', 'createdDateTimeUnix'));
	}, [rows, page, rowsPerPage, searchValue]);

	const updateDescription = async (newRow, oldRow) => {
		const metadata = {
			OData__ExtendedDescription: newRow.Description,
		};
		await handleAddMetadataFileDocument(metadata, oldRow.serverRelativeUrl);
	};

	const updateFileName = async (newRow, oldRow) => {
		const cleanedName = newRow.name.replace(specialChars, '');
		const metadata = {
			FileLeafRef: `${cleanedName}.${oldRow.type}`,
		};
		await handleAddMetadataFileDocument(metadata, oldRow.serverRelativeUrl);
	};

	const processRowUpdate = async (newRow, oldRow) => {
		if (oldRow.Description !== newRow.Description) {
			try {
				await updateDescription(newRow, oldRow);
				dispatch(
					showToast({
						message: 'Description updated!',
						success: true,
					})
				);
			} catch (error) {
				const errorMessage = error?.response?.data?.error?.message?.value ?? '';
				console.log('🙅 ~ ERROR UPDATING METADATA', error);
				dispatch(
					showToast({
						message: `Failed to update description: ${errorMessage}`,
						error: true,
					})
				);
			}
			dispatch(triggerRefresh());
		}
		if (oldRow.name !== newRow.name) {
			try {
				await updateFileName(newRow, oldRow);
				dispatch(
					showToast({
						message: 'File name updated!',
						success: true,
					})
				);
			} catch (error) {
				const errorMessage = error?.response?.data?.error?.message?.value ?? '';
				console.log('🙅 ~ ERROR UPDATING METADATA', error);
				dispatch(
					showToast({
						message: `Failed to update file name: ${errorMessage}`,
						error: true,
					})
				);
			}
			dispatch(triggerRefresh());
		}
		return newRow;
	};

	const handleProcessRowUpdateError = (error) => {
		console.log('Error updating row', error);
	};

	return (
		<Box sx={{ width: '100%' }}>
			<Box width={{ sm: '100%', md: '60%' }} pb='1em'>
				<SearchField
					value={searchValue}
					onChange={(event) => dispatch(setSearchValue(event.target.value))}
					onReset={() => dispatch(setSearchValue(''))}
					helperText={''}
				/>
			</Box>
			{allLoading ? (
				<DataGrid
					sx={{ backgroundColor: 'white' }}
					rows={[{ id: 'loading-skeleton-row' }]}
					columns={columns}
					disableColumnMenu
					disableSelectionOnClick
				/>
			) : !sharepoint?.isValid ? (
				<Typography variant='subtitle1'>
					{`Could not find valid Sharepoint site for ${
						deal ? 'deal' : 'client'
					} owner. Please contact support@folio.insure.`}
				</Typography>
			) : allError ? (
				<Typography variant='subtitle1'>
					There was an error. Please make sure you are signed in and try again.
				</Typography>
			) : displayedFiles.length > 0 && !loading ? (
				<DataGrid
					initialState={{
						sorting: {
							sortModel: [{ field: 'createdDateTimeUnix', sort: 'desc' }],
						},
					}}
					sortingOrder={['desc', 'asc']}
					sx={{
						backgroundColor: 'white',
						'& .MuiDataGrid-editInputCell': {
							backgroundColor: 'transparent',
						},
					}}
					processRowUpdate={processRowUpdate}
					onProcessRowUpdateError={handleProcessRowUpdateError}
					rows={displayedFiles}
					columns={columns}
					disableRowSelectionOnClick
					disableSelectionOnClick
					disableColumnFilter
					disableColumnSelector
					disableColumnMenu
				/>
			) : (
				<NoAttachmentsTable />
			)}
		</Box>
	);
};
