import React, { useMemo, useState, useEffect } from 'react';
import {
	Typography,
	Grid,
	Card,
	Box,
} from '@mui/material';
import {  InstantDealDisplayCard } from '@/components/cards/DealsDIsplayCard';
import ClientTable from '@/components/tables/ClientTable';
import { useSelector } from 'react-redux';
import { IS_DEV } from '@/utils/environment';
import { PageHeader } from '@/components/layouts/PageHeader';

export const ClientsView = () => {
	const account = useSelector((state) => state.msalAccount.account);
	const name = useMemo(() => account?.name.split(' ')[0], [account]);

	return (
		<Box
			sx={{
				backgroundColor: (theme) =>
					theme.palette.mode === 'dark' ? 'neutral.800' : 'neutral.100',
				p: 3,
			}}
		>
			<PageHeader title={`Kia ora ${name}`} showGroupView padding={'1em 0'}/>
			<Grid container spacing={2}>
				<Grid item xs={12} md={3}>
					<InstantDealDisplayCard
						title="New Business Leads"
						pipelineId={process.env.REACT_APP_PIPELINE_NEW_BUSINESS}
					/>
				</Grid>
				<Grid item xs={12} md={3}>
					<InstantDealDisplayCard
						title="Renewals"
						pipelineId={process.env.REACT_APP_PIPELINE_RENEWALS}
					/>
				</Grid>
				<Grid item xs={12} md={3}>
					<InstantDealDisplayCard
						title="Endorsements"
						pipelineId= {process.env.REACT_APP_PIPELINE_ENDORSEMENTS}
					/>
				</Grid>
				<Grid item xs={12} md={3}>
					<InstantDealDisplayCard
						title="Claims"
						pipelineId={process.env.REACT_APP_PIPELINE_CLAIMS}
					/>
				</Grid>
			</Grid>
			<Box
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					padding: '3em 0 1em',
				}}
			>
				<Grid container justifyContent="space-between" alignItems="center">
					<Grid item>
						<Typography variant="h5" style={{ fontWeight: 'bold' }}>
                        Insured Entities
						</Typography>
					</Grid>
				</Grid>
			</Box>
			<Card>
				<ClientTable />
			</Card>
		</Box>
	);
};
