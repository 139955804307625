/* eslint-disable no-mixed-spaces-and-tabs */
import React, {  } from 'react';
import {
	Typography,
	Grid,
	FormGroup,
} from '@mui/material';
import { determineContext } from '@/hooks/determineContext';
import { useUpdateDealPropertiesMutation } from '@/features/deals/dealsApi';
import { OwnerSelect } from '@/components/selects/OwnerSelect';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useSelector } from 'react-redux';
import { theme } from '@/app/theme';
import { useUpdateTicketPropertiesMutation } from '@/features/claims/ticketsApi';
import { useUpdateTaskMutation } from '@/features/engagements/engagementsApi';

export const ChangeAssignee = ({task=null}) => {
	const { deal, objectType, loading } = determineContext();
	

	const account = useSelector((state) => state.msalAccount.account);
	const isDeal = objectType === 'deal';
	
	const email = account?.username;
	const {
		data: userDetails,
	} = useGetUserDetailsQuery(
		{ email },
		{
			skip: !email,
		}
	);
    
	const minimalLabelTheme = theme.components.MuiFormLabel.styleOverrides.minimal;
	const hubspotId = task?.ownerId ?? deal?.hubspot_owner_id ?? userDetails?.hubspotId;
	const [updateDealProperties, { isLoading }] = isDeal ? useUpdateDealPropertiesMutation() : useUpdateTicketPropertiesMutation();
	const [updateTask] = useUpdateTaskMutation();


	const handleAssigneeChange = async (value) => {	
		const params = {
			'hubspot_owner_id': value,
		};
		if (task !== null) {
			await updateTask({
				dealId: deal.hs_object_id,
				taskId: task?.id,
				objectType,
				properties: params,
			});
		} else {
			await updateDealProperties({
				[isDeal ? 'dealId' : 'ticketId']: deal.hs_object_id,
				body: params,
			}).unwrap();
		}
	};

	return (
		<FormGroup row>
			<Typography sx={minimalLabelTheme}>Assignee</Typography>
			<Grid container>
				 <OwnerSelect
					minimal={true}
					initialId={hubspotId}
					disabled={isLoading}
					onChange={(value) => {
						handleAssigneeChange(value);
					}}
				/>
			</Grid>
		</FormGroup>
	);
};
