import React, { useEffect, useState } from 'react';
import {
	Box,
	Card,
	CircularProgress,
	Collapse,
	IconButton,
	Link,
	Stack,
	Typography,
} from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { Delete } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import { folioBlue, formatDate, getUrlFromPipeline } from '@/utils/constants';
import { determineContext } from '@/hooks/determineContext';

const EngagementsAccordion = ({
	title,
	expandedContent,
	content,
	contentPrefix,
	footerContent,
	engagement,
	expandedByDefault = false,
	lastUpdated,
	loading,
	handleDelete,
}) => {
	const [expanded, setExpanded] = useState(expandedByDefault);

	const { objectType } = determineContext();

	const toggleExpanded = () => setExpanded((prev) => !prev);

	const associatedDeal = engagement?.data?.ticket ?? engagement?.data?.deal;
	const dealParams = {
		id: associatedDeal?.id,
		name: associatedDeal?.properties?.dealname ?? associatedDeal?.properties?.subject,
		pipeline: associatedDeal?.properties?.pipeline ?? associatedDeal?.properties?.hs_pipeline,
	};

	const dealLink = () => {
		const path = getUrlFromPipeline(dealParams.pipeline);
		return `${path}/${dealParams.id}`;
	};

	return (
		<Card sx={{ marginBottom: '12px', borderRadius: '4px', boxShadow: 1, p: '1em' }}>
			<Stack
				direction={'row'}
				sx={{
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
			>
				<Stack
					direction={'row'}
					sx={{
						cursor: 'pointer',
						alignItems: 'center',
					}}
				>
					<Stack
						direction={'row'}
						sx={{
							cursor: 'pointer',
							alignItems: 'center',
							// flex: 'none',
						}}
						onClick={toggleExpanded}
					>
						<ArrowForwardIosSharpIcon
							sx={{
								fontSize: '0.9rem',
								color: folioBlue,
								transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)',
								transition: 'transform 0.3s ease',
								mr: '0.5em',
							}}
						/>
						<Typography component='div' variant='task_body'>{title}</Typography>
					</Stack>
					{objectType === 'client' && dealParams.id && (
						<Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
							<Typography variant='task_body' noWrap>
								<span style={{ margin: '0 0.5em' }}>{' | '}</span>
								<Link
									to={dealLink()}
									component={RouterLink}
									sx={{ textDecoration: 'none' }} //, color: 'inherit' }}
								>
									{`Policy Transaction: ${dealParams?.name}`}
								</Link>
							</Typography>
						</Box>
					)}
				</Stack>
				{handleDelete && (
					<>
						{loading ? (
							<Box padding='0.76rem'>
								<CircularProgress size='1rem' />
							</Box>
						) : (
							<IconButton
								aria-label='delete'
								sx={{ fontSize: '0.5rem', marginRight: '-1.em' }}
								onClick={handleDelete}
							>
								<Delete />
							</IconButton>
						)}
					</>
				)}
			</Stack>
			{(content || contentPrefix) &&  <Stack direction={'row'} alignItems='center'>
				{contentPrefix && contentPrefix}
				<Collapse
					in={expanded}
					timeout={300}
					sx={{
						transition: 'height 0.3s ease',
						width: '100%'
					}}
					collapsedSize={'3em'}
				>
					<Typography
						variant='task_body'
						width='100%'
						component='div'
						sx={{
							...(!expanded && {
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
							}),
							transition: 'transform 0.3s ease',
						}}
					>
						{content}
					</Typography>
				</Collapse>
			</Stack>}
			{expandedContent && (
				<Collapse in={expanded} timeout='auto' unmountOnExit>
					<Stack p='1em'>{expandedContent}</Stack>
				</Collapse>
			)}
			<Stack
				direction={'row'}
				justifyContent={footerContent ? 'space-between' : 'flex-end'}
				pt='1em'
			>
				{footerContent && footerContent}
				<Typography variant='task_updated'>
					{`Last updated: ${formatDate(lastUpdated, true)}`}
				</Typography>
			</Stack>
		</Card>
	);
};

export default EngagementsAccordion;
