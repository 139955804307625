import React, { useState, useEffect, useMemo } from 'react';
import {
	Typography,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Paper,
	FormHelperText,
} from '@mui/material';
import { FolioTableHead } from '@/components/table/FolioTableHead';
import { TableProgressIndicator } from '@/components/table/TableProgressIndicator';
import { useGetClientPoliciesQuery } from '@/features/clients/clientsApi';
import { stableSort } from '@/components/table/functions';
import { policyHeadCells } from '@/utils/constants';
import { determineContext } from '@/hooks/determineContext';
import { PolicyRow } from '@/components/tables/PolicyRow';
import { useGetDealPolicyTransactionsQuery } from '@/features/deals/dealsApi';

export const CompleteDealPolicySelection = ({ setSelectedPolicies, setLoading }) => {
	const [policyRows, setPolicyRows] = useState([]);

	const { deal, client, policies } = determineContext();

	const isEndorsement =
		(deal?.pipeline ?? deal?.hs_pipeline) === process.env.REACT_APP_PIPELINE_ENDORSEMENTS;
	const isRenewal =
		(deal?.pipeline ?? deal?.hs_pipeline) === process.env.REACT_APP_PIPELINE_RENEWALS;

	const policyId = useMemo(() => policies[0]?.Id, [policies]);

	const {
		data: policyData,
		isLoading,
		isError: isPoliciesError,
		isFetching,
		isUninitialized: isPoliciesUninitialised,
	} = (isEndorsement || isRenewal)
		? useGetDealPolicyTransactionsQuery(
			{ policyId, dealId: deal?.id ?? deal?.hs_object_id, transactionType: isEndorsement ? 'endorsement' : 'renewal' },
			{ skip: !policyId }
		)
		: useGetClientPoliciesQuery(
			{ insightId: client?.clientRow?.insightId, transactionType: 'newBusiness' },
			{ skip: !client?.clientRow?.insightId }
		);

	const handleCheckboxChange = (event, policyId) => {
		const { checked } = event.target;

		const selectedPolicy = policyRows.find(p => p.Id === policyId);
		// Update the selected state of the clicked policy
		const updatedPolicyRows = policyRows.map((policy) =>
			policy.Id === policyId ? { ...policy, selected: checked } : policy
		);
		setPolicyRows(updatedPolicyRows);
		if (checked) {
			setSelectedPolicies((prevSelected) => [...prevSelected, selectedPolicy]);
		} else {
			setSelectedPolicies((prevSelected) => prevSelected.filter((policy) => policy.Id !== policyId));
		}
	};

	const handleSelectAllCheckbox = (event) => {
		const { checked } = event.target;
		const updatedPolicyRows = policyRows.map((policy) => ({
			...policy,
			selected: checked,
		}));
		setPolicyRows(updatedPolicyRows);

		const filterAssociated = policyRows.filter((policy) => policy.associated === false && (policy.policyTransactions == null || policy.policyTransactions.length > 0));
		// const allPolicyIds = filterAssociated.map((policy) => policy.Id);
		setSelectedPolicies(checked ? filterAssociated : []);
	};

	const error = isPoliciesError;

	const loading = isLoading || isPoliciesUninitialised;
	// const isUpdating = isupdatingPolicies || isLoadingUpdate;

	useEffect(() => setLoading(loading), [loading]);

	useEffect(() => {
		if (policyData) {
			const updatedPolicies = policyData.filter(policy => policy.associated === false).map((policy) => ({
				...policy,
				selected: false,
			}));
			setPolicyRows(updatedPolicies);
		}
	}, [policyData]);

	const policyString = isEndorsement ? 'endorsement' : isRenewal ? 'policy transaction' : 'policies';

	useEffect(() => {
		console.log('Policy rows', policyRows);
	}, [policyRows]);
	
	return (
		<>
			<Typography variant='subtitle2' sx={{ fontWeight: 'bold', marginTop: 3 }}>
				{`Choose the INSIGHT ${policyString} that relate${(isEndorsement || isRenewal) ? 's' : ''} to this deal`}
			</Typography>
			{!(isEndorsement || isRenewal) && <Typography variant='subtitle2' sx={{ marginBottom: 3 }}>
				{`*The selected ${policyString} will be linked to the renewal deal at the end of the term.`}
			</Typography>}
			<TableContainer component={Paper}>
				<Table
					aria-labelledby='tableTitle'
					size='medium'
					aria-label='enhanced table'
					sx={{ minWidth: 700 }}
				>
					<FolioTableHead
						order={'desc'}
						orderBy={'createDate'}
						headCells={policyHeadCells}
						onSelectAllCheck={isEndorsement ? null : handleSelectAllCheckbox}
					/>
					<TableBody>
						{loading || isFetching ? (
							<TableProgressIndicator rowCount={policyHeadCells.length} />
						) : error ? (
							<TableRow>
								<TableCell colSpan={policyHeadCells.length} align='center'>
									<Typography variant='subtitle1'>
										There was an error. Please try again.
									</Typography>
								</TableCell>
							</TableRow>
						) : policyRows.length > 0 ? (
							stableSort(policyRows, (a, b) => a.Id - b.Id).map((policy, index) => (
								<PolicyRow
									handleCheckboxChange={handleCheckboxChange}
									key={`policy-row-index-${index}-${policy.Id}`}
									policy={policy}
								/>
							))
						) : (
							<TableRow>
								<TableCell colSpan={policyHeadCells.length} align='center'>
									<Typography variant='subtitle1'>{`No unlinked ${policyString} found. Please create in INSIGHT first.`}</Typography>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};
