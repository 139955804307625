import React, { useMemo } from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material';
import { folioBlue, getUrlFromPipeline } from '@/utils/constants';
import { useNavigate } from 'react-router-dom';
import { Configure, InstantSearch, useHits } from 'react-instantsearch';
import { dealClient } from '@/utils/typesense';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useGetPipelinePropertiesQuery } from '@/features/pipelines/pipelineApi';
import { useSelector } from 'react-redux';

export const InstantDealDisplayCard = ({ pipelineId, title, url }) => {
	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;

	const pipelineQuery = useGetPipelinePropertiesQuery(pipelineId);

	const { 
		groupView, 
		hubspotId: groupViewHubspotId
	} = useSelector((state) => state.userPrefs);
    
	const { active, inactive } = useMemo(() => {
		const pipelineStages = pipelineQuery.data?.pipelines?.stages ?? [];
		return { 
			active: pipelineStages.filter(stage => stage.metadata?.isClosed != 'true').map(s => s.id), 
			inactive: pipelineStages.filter(stage => stage.metadata?.isClosed == 'true').map(s => s.id)
		};
	}, [pipelineQuery.data]);

	const userQuery = useGetUserDetailsQuery({ email }, {
		skip: !email,
	});
	const userDetailsHubspotId = userQuery.data?.hubspotId;
	const hubspotId = groupViewHubspotId || userDetailsHubspotId;
	const brokerGroupIds = userQuery.data?.brokerGroupArray ?? [];

	const filterSchema = useMemo(() => {
		const stageIds = active.join(',');
		const ownerFilter = groupView ? `brokerGroupId: [${brokerGroupIds.join(',')}]` : `ownerId := ${hubspotId}`;
		const schema = {
			filters: `dealStage: [${stageIds}] && ${ownerFilter}`,
		};
		console.log('Filter schema', schema);
		return schema;
	}, [hubspotId, brokerGroupIds, active]);

	return (
		<InstantSearch searchClient={dealClient} indexName="deals">
			<Configure hitsPerPage={15} {...filterSchema} />
			<InstantDealDisplayCardWrapper title={title} pipelineId={pipelineId} url={url}/>
		</InstantSearch>
	);
};

const InstantDealDisplayCardWrapper = ({ pipelineId, title, ...props }) => {
	const { results } = useHits(props);
	
	const total = results.nbHits;

	const navigate = useNavigate();
	const theme = useTheme();

	const url = getUrlFromPipeline(pipelineId);

	return <Card 
		onClick={() => navigate(url)}
		sx={{ 
			borderRadius: '15px',
			height: '12em',
			[theme.breakpoints.down('md')]: {
				height: '13.5em',
			},
			padding: '1.25em',  
			transition: 'background-color 0.3s', 
			'&:hover': { 
				backgroundColor: '#f0f0f0',
				cursor: 'pointer'
			} 
		}}>
		<Grid
			container
			direction="column"
			justifyContent="space-between"
			alignItems="stretch"
			height="100%"
		>
			<Grid item>
				<Typography variant="card_header">{`Active ${title}`}</Typography>
			</Grid>
			<Grid item>
				<Typography variant="card_h4">{total}</Typography>
			</Grid>
			<Grid item sx={{color: folioBlue}}>
				{`View all ${title}`}
			</Grid>
		</Grid>
	</Card>;
};