import React, { useMemo } from 'react';
import { Grid, Skeleton } from '@mui/material';
import { determineContext } from '@/hooks/determineContext';
import { useUpdateDealRowMutation } from '@/features/deals/dealsApi';
import {
	NEEDS_ANALYSIS,
	NEEDS_ANALYSIS_LABELS,
	SCOPE_OF_ADVICE_LABELS_DOMESTIC,
	SCOPE_OF_ADVICE_LABELS_COMMERCIAL,
	COMMERCIAL,
} from '@/components/forms/CreateNewBusinessForm/validationSchema';
import { NeedsAnalysisSelect } from '@/components/selects/NeedsAnalysisSelect';

export const NeedsAnalysisDropdownContainer = () => {
	const { dealRow } = determineContext();

	const clientType = dealRow?.clientType;

	const riskClasses = dealRow?.riskClasses ?? [];
	const needsAnalysis = useMemo(() => dealRow?.needsAnalysis ?? [], [dealRow?.needsAnalysis]);

	const [updateDeal, { isLoading }] = useUpdateDealRowMutation();

	const handleAnalysisChange = async (key, value) => {
		const existingEntryIndex = needsAnalysis.findIndex((item) => item.key === key);

		let updatedNeedsAnalysis;

		if (existingEntryIndex >= 0) {
			updatedNeedsAnalysis = needsAnalysis.map((item, index) =>
				index === existingEntryIndex ? { key, value } : item
			);
		} else {
			updatedNeedsAnalysis = [...needsAnalysis, { key, value }];
		}

		await updateDeal({
			dealId: dealRow.dealId,
			docId: dealRow.id,
			properties: { [NEEDS_ANALYSIS]: updatedNeedsAnalysis },
		})
			.unwrap()
			.catch((err) => console.log('Error updating', err));
	};

	return (
		<Grid container spacing={2}>
			{clientType === 'domestic' && riskClasses.includes('fullNeedsAnalysis')
				? // Render all the Domestic labels when full needs analysis is selected for a Domestic client
				Object.entries(SCOPE_OF_ADVICE_LABELS_DOMESTIC)
					.filter(([value]) => value !== 'fullNeedsAnalysis')
					.map(([value, label]) => (
						<Grid item xs={12} sm={12} key={`needs-scope-selection-${value}`}>
							{isLoading ? (
								<Skeleton height='3em' />
							) : (
								<NeedsAnalysisSelect
									label={label}
									needsValues={NEEDS_ANALYSIS_LABELS}
									analysisValue={
										needsAnalysis.find((item) => item.key === value)
											?.value || ''
									}
									setAnalysisValue={(val) => handleAnalysisChange(value, val)}
								/>
							)}
						</Grid>
					))
				: riskClasses.includes('fullNeedsAnalysis')
					? // Render Commercial labels if the riskClasses include full needs analysis for Commercial clients
					Object.entries(SCOPE_OF_ADVICE_LABELS_COMMERCIAL)
						.filter(([value]) => value !== 'fullNeedsAnalysis')
						.map(([value, label]) => (
							<Grid item xs={12} sm={12} key={`needs-scope-selection-${value}`}>
								{isLoading ? (
									<Skeleton height='3em' />
								) : (
									<NeedsAnalysisSelect
										label={label}
										needsValues={NEEDS_ANALYSIS_LABELS}
										analysisValue={
											needsAnalysis.find((item) => item.key === value)
												?.value || ''
										}
										setAnalysisValue={(val) => handleAnalysisChange(value, val)}
									/>
								)}
							</Grid>
						))
					: riskClasses.map((value) => (
						<Grid item xs={12} sm={6} key={`needs-scope-selection-${value}`}>
							{isLoading ? (
								<Skeleton height='3em' />
							) : (
								<NeedsAnalysisSelect
									label={
										clientType === COMMERCIAL
											? SCOPE_OF_ADVICE_LABELS_COMMERCIAL[value]
											: SCOPE_OF_ADVICE_LABELS_DOMESTIC[value]
									}
									needsValues={NEEDS_ANALYSIS_LABELS}
									analysisValue={
										needsAnalysis.find((item) => item.key === value)?.value ||
										''
									}
									setAnalysisValue={(val) => handleAnalysisChange(value, val)}
								/>
							)}
						</Grid>
					))}
		</Grid>
	);
};
