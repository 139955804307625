import React, { useMemo } from 'react';
import {
	Typography,
	FormGroup,
	Grid,
	FormControlLabel,
} from '@mui/material';
import {
	GOALS_OF_COVER,
	GOALS_OF_COVER_LABELS,
} from '@/components/forms/CreateNewBusinessForm/validationSchema';
import { determineContext } from '@/hooks/determineContext';
import { ClientCheckbox } from '@/components/buttons/ClientCheckbox';
import { useUpdateDealRowMutation } from '@/features/deals/dealsApi';

export const GoalsOfCover = () => {
	const { dealRow } = determineContext();
	const goalsOfCover = useMemo(() => dealRow[GOALS_OF_COVER] ?? [], [dealRow]);

	const [updateDeal, { isLoading }] = useUpdateDealRowMutation();

	const onChange = async (newValues) => {
		if (newValues.length <= 2) {
			await updateDeal({
				dealId: dealRow.dealId,
				docId: dealRow.id,
				properties: {
					[GOALS_OF_COVER]: newValues,
				}
			}).unwrap();
		}
	};

	return (
		<FormGroup row>
			<Typography variant="form_label">Goals of cover</Typography>
			<Grid container>
				{Object.entries(GOALS_OF_COVER_LABELS).map((entry) => {
					const [value, label] = entry;
					return (
						<Grid item xs={12} sm={6} key={`client-goals-${value}`}>
							<FormControlLabel
								size="small"
								control={
									<ClientCheckbox
										value={value}
										valuesArray={goalsOfCover}
										setValues={onChange}
										disabled={isLoading}
									/>
								}
								label={label}
								sx={{
									'& .MuiTypography-root': {
										fontSize: '12px'
									},
									'& .MuiButtonBase-root': {
										fontSize: '18px'
									},
								}}
							/>
						</Grid>
					);
				})}
			</Grid>
		</FormGroup>
	);
};
