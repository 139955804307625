/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useMemo } from 'react';
import {
	Typography,
	List,
} from '@mui/material';
import { determineContext } from '@/hooks/determineContext';
import { useGetOwnerQuery } from '@/features/user/ownerApi';
import { useDeleteNoteMutation } from '@/features/engagements/engagementsApi';
import { formatDate } from '@/utils/constants';
import { ConfirmDialog } from '@/components/dialogs/ConfirmDialog';
import { useLocation } from 'react-router-dom';
import EngagementsAccordion from '../EngagementsAccordion';
import AttachmentListItem from '@/components/AttachmentListItem';

export const NoteAccordion = ({ note }) => {
	const { deal, objectType, client } = determineContext();
	const ownerQueryParams = { ownerId: note.createdBy ?? note.ownerId };
	const { data: owner } = useGetOwnerQuery(ownerQueryParams, {
		skip: !ownerQueryParams.ownerId,
	});

	const [deleteNote, { isLoading }] = useDeleteNoteMutation();
	const [dialogOpen, setDialogOpen] = useState(false);

	const handleOpenDialog = () => setDialogOpen(true);
	const handleCloseDialog = () => setDialogOpen(false);
	const handleConfirmDialog = async () => {
		handleCloseDialog();
		await archiveNote();
	};
	const archiveNote = async () => {
		await deleteNote({
			dealId: objectType === 'client' ? client.hs_object_id : deal.hs_object_id,
			noteId: note.id,
			objectType: objectType,
		});
	};

	const getFullName = () => {
		return owner ? `${owner?.firstName} ${owner?.lastName}` : 'Unknown';
	};

	const date = useMemo(() => new Date(note.timestamp), [note.timestamp]);
	const lastUpdated = useMemo(() => new Date(note.lastUpdated), [note.lastUpdated]);

	const location = useLocation();

	const noteOpened = useMemo(() => {
		if (location.state?.note) {
			return location.state.note === note?.id;
		}
		return false;
	}, [location?.state?.note]);

	return (
		<>
			<EngagementsAccordion
				title={
					<>
						<span style={{ fontWeight: 'bold' }}>Note</span> by{' '}
						<span>{getFullName()}</span>
						<span style={{ margin: '0 0.5em' }}>{' | '}</span>
						<span style={{ fontWeight: '500' }}>{formatDate(date, true)} </span>
					</>
				}
				expandedByDefault={noteOpened}
				engagement={note}
				lastUpdated={lastUpdated}
				loading={isLoading}
				handleDelete={handleOpenDialog}
				content={note.content}
				{...(note.files.length > 0 && {
					expandedContent: (
						<>
							<List
								dense
								sx={{
									width: '100%',
									'& .MuiListItemIcon-root': {
										minWidth: 0,
									},
									'& .MuiSvgIcon-root': {
										fontSize: 18,
									},
								}}
							>
								{note.files.map((file, i) => (
									<AttachmentListItem
										key={`attachment-${file.id}-${i}`}
										fileId={file.id}
									/>
								))}
							</List>
						</>
					),
					footerContent: (
						<Typography variant='task_updated'>{`${note.files.length} attachments`}</Typography>
					)
				})}
			/>
			<ConfirmDialog
				openDialog={dialogOpen}
				handleClose={handleCloseDialog}
				handleConfirm={handleConfirmDialog}
				actionName={'delete'}
			/>
		</>
	);

	// return (
	// 	<Box mb='12px'>
	// 		<Card mb='12px'>
	// 			<Accordion
	// 				expanded={noteOpened}
	// 				onChange={handleNoteOpened}
	// 				sx={{
	// 					backgroundColor: 'transparent',
	// 					borderRadius: 0,
	// 					boxShadow: 'none',
	// 					transition: 'all 0.3s ease',
	// 				}}
	// 			>
	// 				<Stack>
	// 					<Stack direction='row' justifyContent='space-between'>
	// 						<Stack direction='row' alignContent={'center'}>
	// 							<AccordionSummary
	// 								expandIcon={
	// 									<ArrowForwardIosSharpIcon
	// 										sx={{ fontSize: '0.9rem', color: folioBlue }}
	// 									/>
	// 								}
	// 								aria-controls='panel1bh-content'
	// 								id='panel1bh-header'
	// 								sx={{
	// 									flexDirection: 'row-reverse',
	// 									// width: '100%',
	// 									margin: '0 !important',
	// 									'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
	// 										transform: 'rotate(90deg)',
	// 									},
	// 								}}
	// 							>
	// 								<Typography variant='task_body' sx={{ marginLeft: '0.5em' }}>
	// 									<span style={{ fontWeight: 'bold' }}>Note</span> by{' '}
	// 									<span>{getFullName()}</span>
	// 									<span style={{ margin: '0 0.5em' }}>{' | '}</span>
	// 									<span style={{ color: '#505AFC' }}>
	// 										{' '}
	// 										{formatDate(date, true)}{' '}
	// 									</span>
	// 								</Typography>
	// 							</AccordionSummary>
	// 							{objectType === 'client' && dealParams.id !== null && (
	// 								<Box
	// 									sx={{
	// 										marginTop: noteOpened ? '20px' : '16px',
	// 										transition: 'all 0.3s ease',
	// 									}}
	// 								><Typography variant='task_body'><span style={{ margin: '0 0.5em' }}>{' | '}</span>
	// 										<Link
	// 											to={dealLink()}
	// 											component={RouterLink}
	// 											sx={{ textDecoration: 'none', color: 'inherit' }}
	// 										>
	// 											{`Policy Transaction: ${dealParams?.name}`}
	// 										</Link>
	// 									</Typography>
	// 								</Box>
	// 							)}
	// 						</Stack>
	// 						<div
	// 							style={{
	// 								marginTop: '12px',
	// 								display: 'flex',
	// 								alignItems: 'center',
	// 								justifyContent: 'right',
	// 							}}
	// 						>
	// 							{isLoading ? (
	// 								<Box padding='0.76rem'>
	// 									<CircularProgress size='1rem' />
	// 								</Box>
	// 							) : (
	// 								<IconButton
	// 									aria-label='delete'
	// 									sx={{ fontSize: '0.5rem' }}
	// 									onClick={handleOpenDialog}
	// 								>
	// 								    <Delete />
	// 								</IconButton>
	// 							)}
	// 						</div>
	// 					</Stack>
	// 					<Box
	// 						p={'0 16px 16px'}
	// 						onClick={handleNoteOpened}
	// 						width='100%'
	// 						style={{
	// 							overflow: 'hidden',
	// 							textOverflow: 'ellipsis',
	// 							cursor: 'pointer',
	// 							transition: 'all 0.3s ease',
	// 							...(noteOpened && { display: 'none' }),
	// 						}}
	// 					>
	// 						{!noteOpened && (
	// 							<Typography variant='task_body' noWrap>
	// 								{note.content}
	// 							</Typography>
	// 						)}
	// 					</Box>
	// 				</Stack>
	// 				<AccordionDetails sx={{ borderTop: 'none' }}>
	// 					<Stack width='100%' spacing={1}>
	// 						<Typography variant='task_body'>{note.content}</Typography>
	// 						{note.files.length > 0 && (
	// 							<List
	// 								dense
	// 								sx={{
	// 									width: '100%',
	// 									'& .MuiListItemIcon-root': {
	// 										minWidth: 0,
	// 										marginRight: '12px',
	// 									},
	// 									'& .MuiSvgIcon-root': {
	// 										fontSize: 18,
	// 									},
	// 								}}
	// 							>
	// 								{note.files.map((file, i) => (
	// 									<ListItem disablePadding key={`${file.fileName}-${i}`}>
	// 										<ListItemButton
	// 											target='_blank'
	// 											rel='noopener noreferrer'
	// 											href={file.url}
	// 										>
	// 											<ListItemIcon>
	// 												<AttachFile />
	// 											</ListItemIcon>
	// 											<ListItemText
	// 												sx={{ color: '#505AFC' }}
	// 												primary={`${file.fileName}`}
	// 											/>
	// 										</ListItemButton>
	// 									</ListItem>
	// 								))}
	// 							</List>
	// 						)}
	// 						{/* {objectType === 'client' && dealParams.id !== null && (
	// 							<Stack direction='row' spacing={2}>
	// 								<Typography variant='task_body'>{`${dealParams?.name}:`}</Typography>
	// 								<Link
	// 									to={dealLink()}
	// 									component={RouterLink}
	// 									sx={{ textDecoration: 'none' }}
	// 								>
	// 									<Typography variant='blue_text'>
	// 										View record keeping
	// 									</Typography>
	// 								</Link>
	// 							</Stack>
	// 						)} */}
	// 					</Stack>
	// 				</AccordionDetails>
	// 			</Accordion>
	// 			<Grid container px={'16px'}>
	// 				<Grid item xs={6}>
	// 					{/* {objectType === 'client' && dealParams.id !== null && (
	// 						<Typography variant='task_updated'>
	// 							{`${transactionType}${dealParams?.id}`}
	// 						</Typography>
	// 					)} */}
	// 				</Grid>
	// 				<Grid item xs={6} display='flex'>
	// 					<Typography variant='task_updated' textAlign='right' width='100%'>
	// 						{`Last updated: ${formatDate(lastUpdated, true)}`}
	// 					</Typography>
	// 				</Grid>
	// 			</Grid>
	// 		</Card>
	// 		<ConfirmDialog
	// 			openDialog={dialogOpen}
	// 			handleClose={handleCloseDialog}
	// 			handleConfirm={handleConfirmDialog}
	// 			actionName={'delete'}
	// 		/>
	// 	</Box>
	// );
};
