import React, { useEffect, useRef } from 'react';
import { useLoadScript } from '@react-google-maps/api';
import TextField from '@mui/material/TextField';
import { IconButton, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const libraries = ['places'];

const GooglePlacesAutocomplete = ({ onSelect }) => {
	const inputRef = useRef(null);

	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACES_KEY,
		libraries,
	});

	useEffect(() => {
		const styleElement = document.createElement('style');
		styleElement.innerHTML = `
			.pac-container {
				z-index: 2000 !important; 
			}
		`;
		document.head.appendChild(styleElement);

		if (isLoaded && inputRef.current) {
			const inputElement = inputRef.current.querySelector('input');
			if (inputElement) {
				const autocomplete = new window.google.maps.places.Autocomplete(inputElement, {
					types: ['address'],
					componentRestrictions: { country: "nz" },
				});
				autocomplete.addListener('place_changed', () => {
					const place = autocomplete.getPlace();
					console.log('Selected place:', place);
					onSelect(place);
				});
			}
		}

		return () => {
			if (styleElement) {
				document.head.removeChild(styleElement);
			}
		};
	}, [isLoaded]);

	return (
		<TextField
			inputRef={(input) => {
				if (input) {
					inputRef.current = input.parentElement;
				}
			}}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<IconButton sx={{ padding: '0' }}>
							<SearchIcon />
						</IconButton>
					</InputAdornment>
				),
			}}
			variant="outlined"
			fullWidth
		/>
	);
};

export default GooglePlacesAutocomplete;
